<template>
  <div class="Dshowroom">
    <iframe class="iframeBox" :src="dShowRoomUrl"></iframe>
  </div>
</template>
<script>
import clueServices from '@/services/clueServices'
import loading from '@/utils/loading'
export default {
  data() {
    return {
      dShowRoomUrl: ''
    }
  },
  mounted() {
    this.getUrl()
  },
  methods: {
    async getUrl() {
      const language = window.navigator.language.toLocaleLowerCase().includes('en') ? 'en' : 'zh'
      loading.showLoading()
      const result = await clueServices.getShowRoomUrl({ scrmUri: 'dshowRoomh5' })
      loading.hideLoading()
      this.dShowRoomUrl = `${result}?scrmToken=${sessionStorage.getItem('Token')}&clientType=2&language=${language}`
    },
  }
}
</script>
<style lang="less" scoped>
.Dshowroom{
  .iframeBox{
    width: 100%;
    height: 100vh;
  }
}
</style>